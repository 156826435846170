import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/details/listApprovedModal.scss";
import {
  LdsButton,
  LdsModal,
  LdsTable,
  LdsPagination,
  LdsSearch,
  LdsCheckbox,
  LdsLoadingSpinner,
  LdsIcon,
  LdsScrollingCheckboxGroup,
} from "@elilillyco/ux-lds-react";
import { FETCH_USER_LIST } from "../../store/admin";
const ListApprovedModal = ({
  isListAppModalOpen,
  setListAppModalOpen,
  msg,
  setMsg,
  updateSpecialityDetails,
  addedSpecialtyCodes,
  uncommonRespCodes,
  delSpecialtyCodes,
  department,
  quarter
}) => {
  const thead = [
    "Specialty Code",
    "Specialty Description",
    "Incentivized",
    "Responsibility Code",
    "Status",
  ];
  const theadSf = ["Specialty Code", "Specialty Description", "Status"];
  const [allChanges, setAllChanges] = useState([]);
  const [userOptions, setuserOptions] = useState([]);
  const [selectedapprovers, setselectedapprovers] = useState([]);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (isListAppModalOpen) {
  //     console.log("dispatched userlist");
  //     dispatch(FETCH_USER_LIST());
  //   }
  // }, [isListAppModalOpen]);
  const { userTableList, Brands, loading } = useSelector(({ admin }) => admin);
  useEffect(() => {
    if (msg === "Are you sure want to request for Review ?") {
      setuserOptions(
        userTableList
          .filter((value) => {
            if (value.active) {
              if (quarter) {
                return value.role === "spclty_hub_incentivized_approver";
              } else {
                return value.role === "spclty_hub_mlr_reviewer";
              }
            } else {
              return null;
            }
          })
          .map((value) => {
            return {
              label:
                value.first_name +
                " " +
                value.last_name +
                " (" +
                value.email_id +
                ")",
              value: value.uuid,
            };
          })
      );
    } else {
      setuserOptions(
        userTableList
          .filter((value) => {
            if (value.active) {
              if (quarter) {
                return value.role === "spclty_hub_incentivized_approver"; 
              }
              return value.role === "spclty_hub_mlr_approver";
            } else {
              return null;
            }
          })
          .map((value) => {
            return {
              label:
                value.first_name +
                " " +
                value.last_name +
                " (" +
                value.email_id +
                ")",
              value: value.uuid,
            };
          })
      );
    }
  }, [userTableList, msg, department]);
  const returnOverview = () => {
    setListAppModalOpen(false);
  };
  const handleSelectDeselectAll = () => {
    if (userOptions?.length === selectedapprovers?.length) {
      handleApproverChange([]);
    } else {
      handleApproverChange(userOptions.map(({ value }) => value));
    }
  };
  const handleApproverChange = (selectedapprovers) => {
    setselectedapprovers(selectedapprovers);
  };
  useEffect(() => {
    if (
      (delSpecialtyCodes.length > 0 &&
        msg === "Are you sure want to approve ?") ||
      (addedSpecialtyCodes.length > 0 &&
        msg === "Are you sure want to approve ?") ||
      (uncommonRespCodes.length > 0 && msg === "Are you sure want to approve ?")
    ) {
      setAllChanges([
        ...addedSpecialtyCodes,
        ...uncommonRespCodes,
        ...delSpecialtyCodes,
      ]);
    }
  }, [delSpecialtyCodes, addedSpecialtyCodes, uncommonRespCodes]);
  const confirmSubmission = () => {
    updateSpecialityDetails(selectedapprovers);
  };
  const showTable = () => {
    if (
      (delSpecialtyCodes.length > 0 &&
        msg === "Are you sure want to approve ?") ||
      (addedSpecialtyCodes.length > 0 &&
        msg === "Are you sure want to approve ?") ||
      (uncommonRespCodes.length > 0 && msg === "Are you sure want to approve ?")
    ) {
      return true;
    }
    return false;
  };
  const getTableColumn = () => {
    if (sessionStorage.getItem("role") === "spclty_hub_mlr_approver") {
      return theadSf;
    }
    return thead;
  };
  const checkIncenApproverRole = () => {
    if (sessionStorage.getItem("role") === "spclty_hub_incentivized_approver") {
      return true;
    }
    return false;
  };
  const noUpdates = () => {
    if (msg === "Are you sure want to approve ?" && allChanges.length === 0) {
      return true;
    }
    return false;
  };
  return (
    <>
      <LdsModal
        modalId="list-app-modal"
        open={isListAppModalOpen}
        setModalOpen={setListAppModalOpen}
        // heading="Add Specialty"
      >
        <div>
          <div class="icon-size">
            <LdsIcon
              name="CheckCircleFill"
              style={{ fill: "#31855b", maxWidth: "60px", maxHeight: "60px" }}
            />
          </div>
          {/* <h1>Inside List Approved Modal</h1> */}
          <div class="msg-data">
            <h2>{msg}</h2>
          </div>
          {msg === "Are you sure want to request for approval ?" && (
            <div class="msg-data">
              {/* <div>
                <LdsCheckbox
                  label={
                    department === "RETAIL"
                      ? "Route to MLR Approver Group"
                      : "Route to Incentivized Approver Group"
                  }
                  id="selectgrpnotify"
                  required={false}
                  name="selectgrpnotify"
                  value={department}
                  checked={selectedapprovers.length === 0}
                  onChange={(event) =>
                    event.target.checked ? setselectedapprovers([]) : null
                  }
                />
              </div>
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <h2>OR</h2>
              </div> */}
              <div>
                {!loading && (
                  <LdsScrollingCheckboxGroup
                    label={
                      <>
                        Select Approvers
                        <LdsButton linkButton onClick={handleSelectDeselectAll}>
                          {userOptions?.length === selectedapprovers?.length
                            ? "Deselect"
                            : "Select"}
                          All
                        </LdsButton>
                      </>
                    }
                    labelId="userselect"
                    options={userOptions}
                    values={selectedapprovers}
                    setValues={handleApproverChange}
                  />
                )}
              </div>
            </div>
          )}
          {msg === "Are you sure want to request for Review ?" && (
            <div class="msg-data">
              {/* <div>
                <LdsCheckbox
                  label={
                    department === "RETAIL"
                      ? "Route to MLR Approver Group"
                      : "Route to Incentivized Approver Group"
                  }
                  id="selectgrpnotify"
                  required={false}
                  name="selectgrpnotify"
                  value={department}
                  checked={selectedapprovers.length === 0}
                  onChange={(event) =>
                    event.target.checked ? setselectedapprovers([]) : null
                  }
                />
              </div>
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <h2>OR</h2>
              </div> */}
              <div>
                {!loading && (
                  <LdsScrollingCheckboxGroup
                    label={
                      <>
                        Select Reviewers
                        <LdsButton linkButton onClick={handleSelectDeselectAll}>
                          {userOptions?.length === selectedapprovers?.length
                            ? "Deselect"
                            : "Select"}
                          All
                        </LdsButton>
                      </>
                    }
                    labelId="userselect"
                    options={userOptions}
                    values={selectedapprovers}
                    setValues={handleApproverChange}
                  />
                )}
              </div>
            </div>
          )}
          {showTable() ? (
            <div>
              <LdsTable
                id="speciality-list-table"
                flat
                sortable
                className="stl-table-list"
                layoutClass="layout-auto"
              >
                <thead>
                  <tr>
                    {getTableColumn().map((itm, index) => (
                      <th>{itm}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allChanges.map((table) => (
                    <tr className={table.status}>
                      <td>{table.specialty_code}</td>
                      <td>{table.specialty_description}</td>
                      {checkIncenApproverRole() ? (
                        <td>{table.incentivized ? "Yes" : "No"}</td>
                      ) : (
                        <></>
                      )}
                      {checkIncenApproverRole() ? (
                        <td>{table.responsibility_code}</td>
                      ) : (
                        <></>
                      )}
                      <td>{table.status}</td>
                    </tr>
                  ))}
                </tbody>
              </LdsTable>
            </div>
          ) : (
            <></>
          )}
          {noUpdates() ? (
            <div className="mrgn">
              {" "}
              <h3>No updates were made to existing list.</h3>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="col">
              <span className="floatRight mt-4">
                <span className="mr-4">
                  <LdsButton
                    // icon="Plus"
                    // iconPosition="before"
                    classes="button-icon-left blue-bdr-btn"
                    onClick={returnOverview}
                  >
                    No
                  </LdsButton>
                </span>
                <LdsButton
                  // icon="Plus"
                  // iconPosition="before"
                  classes="button-icon-left blue-btn"
                  onClick={confirmSubmission}
                  disabled={
                    selectedapprovers.length === 0 &&
                    msg !== "Are you sure want to approve ?" &&
                    msg !== "Are you sure you want to Submit Review ?"
                  }
                >
                  Yes
                </LdsButton>
              </span>
            </div>
          </div>
        </div>
      </LdsModal>
    </>
  );
};
export default ListApprovedModal;
