import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  LdsBadge,
  LdsButton,
  LdsLink,
  LdsToast,
  useLdsModal,
  useToastContext,
} from "@elilillyco/ux-lds-react";

import DeleteModal from "./DeleteModal";

import { UPDATE_LIST_STATUS, FETCH_OVERVIEW } from "../../store/overview";

import "./../../assets/scss/overview/overviewTile.scss";
import { useEffect, useState } from "react";
import ArchiveConfirmationModal from "./ArchiveConfirmationModal";
import infoToastConfig from "../shared/Toast";

const OverviewTile = ({
  listRecords,
  isModalOpen,
  setIsModalOpen,
  showSuccessToast,
  filters,
  isCurrent,
}) => {
  const userId = useSelector(({ user }) => user.uuid);
  const { selectedBrand } = useSelector(({ overview }) => overview);

  const { addToast } = useToastContext();

  const dispatch = useDispatch();

  const [visibleListRecords, setVisibleListRecords] = useState([]);
  const [archiveListID, setArchiveListID] = useState("");

  const {
    isModalOpen: isArchiveConfirmationModalOpen,
    setIsModalOpen: setIsArchiveConfirmationModalOpen,
  } = useLdsModal();

  /**
   * Change the date format to MMMM DD, YYYY
   *
   * @param {Date} dateObj
   * @returns string of formatted date
   */
  const convertDateFormat = (dateObj) =>
    dateObj ? moment(dateObj).format("MMMM DD, YYYY") : "";

  /**
   * Get the user full name
   *
   * @param {Object} details
   * @returns string of user name
   */
  const getCreatedBy = (details) =>
    `${details?.first_name} ${details?.last_name}`.trim();

  /**
   * Checks if user is a viewer
   *
   * @returns true if user is viewer else false
   */
  const isViewerRole = () =>
    sessionStorage.getItem("role") === "spclty_hub_viewer";

  /**
   * Checks if user is a super admin
   *
   * @returns true if user is super admin else false
   */
  const isSuperAdminRole = () =>
    sessionStorage.getItem("role") === "spclty_hub_super_admin";

  /**
   * Delete the list and show the modal
   *
   * @param {String} itemID
   */
  const deleteList = async (itemID) => {
    const { meta } = await dispatch(
      UPDATE_LIST_STATUS({
        updated_by: userId,
        active: false,
        deleteDraftID: itemID,
      })
    );
    if (meta.requestStatus === "fulfilled") {
      setIsModalOpen(true);
    }
  };

  const dateToUse = (record) => {
    return record.list_status.toUpperCase() === "APPROVED"
      ? record.updated_date
      : record.created_date;
  };

  const sortDate = (array) => {
    const copy = JSON.parse(JSON.stringify(array));
    copy.sort((a, b) => {
      const aDate = dateToUse(a);
      const bDate = dateToUse(b);

      if (aDate < bDate) {
        return 1;
      }
      if (aDate > bDate) {
        return -1;
      }
      return 0;
    });
    return copy;
  };

  const sortRecords = () => {
    let commercialRecords = [];
    let medicalRecords = [];
    visibleListRecords
      .filter((record) => record.show)
      .forEach((record) => {
        if (record.department_field.toUpperCase() === "RETAIL") {
          commercialRecords.push(record);
        } else {
          medicalRecords.push(record);
        }
      });
    commercialRecords = sortDate(commercialRecords);
    medicalRecords = sortDate(medicalRecords);

    return [commercialRecords, medicalRecords];
  };

  const archiveList = async () => {
    const { meta } = await dispatch(
      UPDATE_LIST_STATUS({
        updated_by: userId,
        list_status: "ARCHIVE",
        uuid: archiveListID,
      })
    );

    if (meta.requestStatus === "fulfilled") {
      dispatch(
        FETCH_OVERVIEW({
          brand: selectedBrand,
          created_by: userId,
          user_role: sessionStorage.getItem("role"),
        })
      );
    } else {
      addToast(infoToastConfig("Failed to archive the list.", "error"));
    }
  };

  useEffect(() => {
    if (archiveListID) {
      setIsArchiveConfirmationModalOpen(true);
    }
  }, [archiveListID]);

  useEffect(() => {
    if (!isArchiveConfirmationModalOpen) {
      setArchiveListID("");
    }
  }, [isArchiveConfirmationModalOpen]);

  const renderList = (list) =>
    list.map((item, index) => {
      return (
        <div key={`listItem_${index}`} className="list-blk">
          <hr className="mt-3 lds-hr" />
          <div className="list-content">
            <div className="created-txt">
              {convertDateFormat(
                item.list_status.toUpperCase() === "APPROVED"
                  ? item.updated_date
                  : item.created_date
              )}{" "}
              | Created by {getCreatedBy(item.created_by)}
              {item.list_status &&
              item.list_status.toUpperCase() !== "ARCHIVE" ? (
                <LdsBadge
                  className={`ml-4 ${
                    item.list_status.toUpperCase() === "DRAFT"
                      ? "draft-badge"
                      : "green-btn"
                  }`}
                >
                  {item.list_status.toUpperCase()}
                </LdsBadge>
              ) : (
                <></>
              )}
              {item.list_quarter !== "" ? (
                <LdsBadge className="ml-4 light-green-btn">SALESFORCE</LdsBadge>
              ) : (
                <LdsBadge className="ml-4 grey-clr">MLR</LdsBadge>
              )}
            </div>
            <div className="row">
              <div className="col col-12 col-md item-title">
                <LdsLink href={`/details/${item.uuid}`}>
                  {item.list_title}
                </LdsLink>
              </div>
              <div className="col col-12 col-md del-link d-flex justify-end">
                {item.list_status.toUpperCase() !== "DRAFT" &&
                isSuperAdminRole() &&
                isCurrent ? (
                  <LdsButton
                    linkButton
                    onClick={() => setArchiveListID(item.uuid)}
                  >
                    Archive List
                  </LdsButton>
                ) : (
                  <></>
                )}
                {item.list_status.toUpperCase() === "DRAFT" &&
                !isViewerRole() ? (
                  <LdsButton
                    linkButton
                    onClick={() => deleteList(item.uuid)}
                    classes="ml-4"
                  >
                    Delete draft
                  </LdsButton>
                ) : (
                  <></>
                )}
                {item.list_status.toUpperCase() === "APPROVED" &&
                isSuperAdminRole() ? (
                  <LdsButton
                    linkButton
                    onClick={() => deleteList(item.uuid)}
                    classes="ml-4"
                  >
                    Delete Approved
                  </LdsButton>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });

  useEffect(() => {
    const recordsCopy = JSON.parse(JSON.stringify(visibleListRecords));

    if (Object.keys(filters).every((filter) => !filters[filter])) {
      recordsCopy.forEach((record) => (record.show = true));
    } else {
      recordsCopy.forEach((record) => (record.show = false));

      recordsCopy.forEach((record) => {
        let shouldShowRecord = true;
        if (filters.SALESFORCE) {
          shouldShowRecord &= record.list_quarter !== "";
        }
        if (filters.MLR) {
          shouldShowRecord &= record.list_quarter === "";
        }
        if (filters.APPROVED) {
          shouldShowRecord &= record.list_status.toUpperCase() === "APPROVED";
        }
        if (filters.DRAFT) {
          shouldShowRecord &= record.list_status.toUpperCase() === "DRAFT";
        }

        record.show = shouldShowRecord;
      });
    }
    setVisibleListRecords(recordsCopy);
  }, [filters]);

  useEffect(() => {
    let recordsCopy = JSON.parse(JSON.stringify(listRecords));
    recordsCopy = recordsCopy.map((record) => {
      return { ...record, show: true };
    });
    setVisibleListRecords(recordsCopy);
  }, [listRecords]);

  const recordsToDisplay = sortRecords().filter((list) => list.length > 0);

  return (
    <div>
      {recordsToDisplay.length > 0 ? (
        recordsToDisplay.map((list, index) => (
          <>
            <div className="mt-4">
              <LdsBadge
                className="dept-badges"
                style={{ fontSize: "1.25rem", padding: "0.5rem 0.75rem" }}
              >
                { list[0].department_field.toUpperCase() === "RETAIL" ? "COMMERCIAL" : "MEDICAL"}
              </LdsBadge>
            </div>
            {renderList(list)}
          </>
        ))
      ) : (
        <p style={{ marginTop: "1.5rem" }}>No Record Found</p>
      )}

      {isModalOpen && (
        <DeleteModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          showSuccessToast={showSuccessToast}
        />
      )}

      {isArchiveConfirmationModalOpen && (
        <ArchiveConfirmationModal
          open={isArchiveConfirmationModalOpen}
          setModalOpen={setIsArchiveConfirmationModalOpen}
          archiveList={archiveList}
        />
      )}
      <LdsToast />
    </div>
  );
};

export default OverviewTile;
